import { Flex } from '@chakra-ui/react';
import React from 'react'
import { useParams } from 'react-router-dom'
import CandidateRegisterForm from '../Forms/CandidateRegisterForm';

const CandidatRegistrationComponent = () => {
    const { team_id } = useParams();
    return (
        <>
            <CandidateRegisterForm />
        </>
    )
}

export default CandidatRegistrationComponent