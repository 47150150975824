import React, { useContext, useEffect, useRef, useState } from 'react'
import { IconButton, Box, Heading, Flex, Text, Tag, TagLeftIcon, TagLabel, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, useDisclosure, useToast, Card, Stack, Badge } from '@chakra-ui/react';
import { FaTrash, FaPenToSquare, FaEye, FaMapPin, FaFileSignature, FaBusinessTime, FaCoins, FaMoneyBill } from "react-icons/fa6";
import { useNavigate } from "react-router-dom"
import { removeJobOffer } from "../API/JobOffers";
import { UserContext } from '../Authentication/ProtectedRoute';

const JobOfferHeader = ({ _id, name, content, team, tags, description, location, contract_type, contract_time, salary, publicationDate, expirationDate }) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)
    const toast = useToast();
    const handleDeletion = async () => {
        try {
            if (!await removeJobOffer(toDeletion)) {
                throw new Error('Failed to fetch data');
            } else {
                toast({
                    title: 'Offre d\'emploi supprimée',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                navigate('/paul-emploi')
            }

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }


    return (
        <Card w='100%' boxShadow={"base"} p={4} pt={2} color="gray.700">
            <Flex alignItems="baseline" gap={4}>
                <Flex direction={"column"}>
                    <Flex alignItems={"center"} gap={4} flexFlow={"row wrap"}>
                        <Text as='b' fontSize='4xl'>{name}</Text>
                        <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag>
                    </Flex>
                    <Text maxWidth={"90%"}>
                        {description}
                    </Text>
                </Flex>
                {user.roles !== "candidate" ? <Flex ml="auto">
                    <IconButton
                        size='sm'
                        aria-label='Update'
                        colorScheme='teal'
                        icon={<FaPenToSquare />}
                        onClick={() => navigate(`/paul-emploi/update/${_id}`)}
                        mr={1}
                    />
                    <IconButton
                        size='sm'
                        colorScheme='red'
                        aria-label='Delete'
                        icon={<FaTrash />}
                        onClick={() => {
                            setToDeletion(_id)
                            onOpen()
                        }}
                    />
                </Flex> : null}
            </Flex>
            <Flex mt={6} alignItems="baseline" gap={4} flexFlow={"column wrap"}>
                <Heading as='h2' size='md'>Détail de l'offre</Heading>
                <Flex flexFlow={"row wrap"} gap={2}>
                    {location && <Tag colorScheme='gray'><Flex gap={2}><FaMapPin />{location}</Flex></Tag>}
                    {contract_type && <Tag colorScheme='gray'><Flex gap={2}><FaFileSignature />{contract_type}</Flex></Tag>}
                    {contract_time && <Tag colorScheme='gray'><Flex gap={2}><FaBusinessTime />{contract_time}</Flex></Tag>}
                    {salary && <Tag colorScheme='gray'><Flex gap={2}><FaMoneyBill />{salary && salary[0]}€ - {salary && salary[1]}€</Flex></Tag>}
                </Flex>
                <Heading mt={4} as='h2' size='md'>Compétences</Heading>
                <Flex flexFlow={"row wrap"} gap={2}>
                    {tags?.map(tag => <Tag key={tag} colorScheme='gray'>{tag}</Tag>)}
                </Flex>
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de l'offre d'emploi
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer l'offre d'emploi <b>{toDeletion}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Card>
    )
}

export default JobOfferHeader