import { useContext } from 'react';
import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';

import { FaPlusSquare } from 'react-icons/fa';
import { Box, Button, Container, Flex, Heading, Input, Modal, ModalBody, useToast, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, Text, useDisclosure } from '@chakra-ui/react';
import { API_URL } from "../keys";
import { UserContext } from '../Authentication/ProtectedRoute';
import QuestionnairesTable from '../Components/QuestionnairesTable'
import { FaFileImport } from 'react-icons/fa6';

const QuestionnairesComponent = () => {
    const { user } = useContext(UserContext)
    const [questionnaires, setQuestionnaires] = useState([])
    const [searchBar, setSearchBar] = useState("")
    const [googleForms, setGoogleForms] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    useEffect(() => {
        loadUserQuestionnaires()
    }, [])

    const loadUserQuestionnaires = async () => {
        try {
            const response = await fetch(API_URL + '/questionnaires/getuserquestionnaires', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setQuestionnaires(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const loadFromGoogle = async () => {
        setLoading(true);
        const request = await fetch(API_URL + '/questionnaires/createFromGoogle', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                links: googleForms
            })
        })
        await request.json();
        setLoading(false);
        loadUserQuestionnaires();
        setGoogleForms('');
        onClose();
        toast({
            title: 'Questionnaires importés.',
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Importer depuis Google</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                        {!user.google ?
                            <>
                                <Text textAlign={"justify"}>Vous devez vous connecter pour importer des Google Form</Text>
                                <Button textAlign={"center"} colorScheme={"teal"} mt={4} onClick={async () => {
                                    const response = await fetch(API_URL + '/auth/google', {
                                        credentials: 'include',
                                    });
                                    const data = await response.json();
                                    window.location.href = data
                                }}>Se connecter pour importer des Google Form</Button>
                            </>

                            : <>
                                <Textarea value={googleForms} placeholder="Listez vos Google Form ici, un par ligne" onChange={e => setGoogleForms(e.target.value)}></Textarea>
                            </>
                        }
                    </ModalBody>

                    <ModalFooter display={"grid"} gap={4} gridTemplateColumns={"1fr 1fr"}>

                        {user.google &&
                            <>
                                <Button variant={'ghost'} colorScheme='blue' onClick={onClose}>
                                    Fermer
                                </Button>
                                <Button
                                    isLoading={loading}
                                    colorScheme="teal"
                                    onClick={() => loadFromGoogle()}
                                    disabled={loading}
                                >
                                    {loading ? 'Importation en cours...' : 'Importer'}
                                </Button>
                            </>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal >
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>

                    <Heading mb={4} color={"gray.700"}>Les Questionnaires</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                    <Button leftIcon={<FaPlusSquare />} onClick={() => navigate("/questionnaire/update/new")} size="sm" colorScheme="teal" variant="ghost">
                        Créer un questionnaire
                    </Button>
                    <Button leftIcon={<FaFileImport />} onClick={onOpen} size="sm" colorScheme="teal" variant="ghost">
                        Importer des questionnaires
                    </Button>
                </Flex>
                <Box my={4}>
                    <Input variant='flushed' placeholder='Rechercher un questionnaire' value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                </Box>
                <Box w='100%' boxShadow={"base"} borderRadius={"md"}>
                    <QuestionnairesTable loading={loading} questionnaires={questionnaires.filter(row => row.questionnaire.name.toLowerCase().includes(searchBar.toLowerCase()) || row.questionnaire.description.toLowerCase().includes(searchBar.toLowerCase()))} reload={loadUserQuestionnaires} />
                </Box>
            </Container>
        </>
    )
}

export default QuestionnairesComponent