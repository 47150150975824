import { Box } from '@chakra-ui/react';
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfos, logout } from "../API/Users";
import Footer from "../Layout/Footer";
import Navbar from "../Layout/Navbar";

// Création du UserContext
export const UserContext = createContext({
    user: { firstname: undefined, lastname: undefined, roles: undefined, email: undefined, teams: [] },
    setUser: () => { }
});

const ProtectedRoute = ({ component: Component, adminOnly, ...restOfProps }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({ firstname: undefined, lastname: undefined, roles: undefined, email: undefined, teams: [] });
    const [loading, setLoading] = useState(true); // Ajout d'un état de chargement

    useEffect(() => {
        const loadData = async () => {
            try {
                const loadedUser = await getUserInfos();

                if (loadedUser.message) {
                    await logout();
                    navigate("/login");
                    return;
                } else if (adminOnly && loadedUser.roles !== "administrator") {
                    await logout();
                    navigate("/not_found");
                    return;
                } else {
                    setUser(loadedUser);
                }
            } catch (error) {
                navigate("/login");
                return;
            } finally {
                setLoading(false); // Fin du chargement
            }
        };

        loadData();

    }, [adminOnly, navigate]);

    if (loading) {
        return <div></div>; // Affichage pendant le chargement
    }

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <Navbar />
            <Box minHeight="calc(100vh - 180px)">
                <Component {...restOfProps} />
            </Box>
            <Footer />
        </UserContext.Provider>
    );
};

export default ProtectedRoute;