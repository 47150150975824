import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const CreateJobOfferWysiwygComponent = ({ handleEditorChange, defaultValue }) => {
  return (
    <Editor
      apiKey="ncbe553vnl3y8worxq9kvsmdg3tqw5t0vt28s6xqwqtvhn8j"
      value={defaultValue}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'lists',
          'fontsize'
        ],
        toolbar: 'undo redo | fontsizeinput | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        inline_styles: true,
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',

      }}

      onEditorChange={handleEditorChange}
    />
  );
};

export default CreateJobOfferWysiwygComponent;