import {
    Box,
    Divider,
    HStack,
    IconButton,
    Image,
    Link,
    Stack,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { BsDiscord, BsInstagram, BsTwitterX } from 'react-icons/bs';
import HoritzontalLogo from '../Resources/Logos/HoritzontalLogoTransparency.png';
import CustomLink from '../Utils/CustomLink';

const Footer = () => {
    return (
        <Box mt={"auto"} boxShadow={"base"}>
            <Divider width="100%" />
            <Box
                p={5}
                width="100%"
                display="grid"
                alignItems="center"
                gap={{ base: 8, md: 0 }}
                gridTemplateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }}
            >
                <Box display="flex" flexDirection="column">
                    <Link href="" isExternal display="flex" justifyContent={{ base: "center", md: "flex-start" }}>
                        <Image w="150px" src={HoritzontalLogo} alt="TemplatesKart" />
                    </Link>
                    <Text mt={2} color="gray.500" fontSize="md" textAlign={{ base: "center", md: "left" }}>
                        Sélectionner l'excellence avec simplicité.
                    </Text>
                </Box>
                <Box gap={3} display="flex" flexDirection="column" alignItems="center">
                    <Text fontSize="md" fontWeight="bold">
                        Nos réseaux
                    </Text>
                    <Box gap={1} display="flex">
                        <IconButton
                            icon={<BsDiscord />}
                            as={Link}
                            href="https://discord.gg/B3NP7SHT4H"
                            target="_blank"
                            rounded="md"
                            color="white"
                            bg="purple.500"
                            _hover={{ bg: 'purple.600' }}
                        />
                        <IconButton
                            icon={<BsTwitterX />}
                            as={Link}
                            href="#"
                            target="_blank"
                            rounded="md"
                            color="white"
                            bg="black"
                            _hover={{ bg: 'white', color: 'black', border: "1px solid black" }}
                        />
                        <IconButton
                            icon={<BsInstagram />}
                            as={Link}
                            href="#"
                            target="_blank"
                            rounded="md"
                            color="white"
                            bg="orange.500"
                            _hover={{ bg: 'orange.600' }}
                        />
                    </Box>
                </Box>

                <HStack
                    spacing={4}
                    d={'flex'}
                    justifyContent={{ base: "center", md: "flex-end" }}
                >
                    <VStack spacing={1} alignItems={{ base: "center", md: "flex-end" }}>
                        <Text fontSize="md" fontWeight="bold">
                            A propos
                        </Text>
                        <CustomLink linkto="/mentions-legales" >Mentions Légales</CustomLink>
                    </VStack>
                </HStack>
            </Box>
        </Box >

    );
};

export default Footer;