import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Container, Flex, FormControl, FormLabel, Grid, Heading, Input, InputGroup, InputRightElement, Select, Tag, TagCloseButton, TagLabel, Textarea, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FaArrowLeft, FaSave } from 'react-icons/fa'
import CreateJobOfferWysiwygComponent from '../Components/CreateJobOfferWysiwygComponent'
import { UserContext } from '../Authentication/ProtectedRoute'
import onKeyPressEnter from '../Utils/OnKeyPressEnter'
import { getJobOfferById, createJobOffer } from "../API/JobOffers";
import { formatDateForInput } from "../Utils/FormatDate"

const JobOfferUpdateComponent = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id === "new" ? null : params.id;
    const [newTag, setNewTag] = useState("")
    const [jobOfferContent, setJobOfferContent] = useState({
        content: "",
        name: "",
        tags: [],
        team: null,
        location: "",
        contract_type: "",
        contract_time: "",
        description: "",
        salary: [0, 0],
        publicationDate: new Date(),
        expirationDate: null
    })
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (id) {
            // Get current job offer from API
            getJobOfferById(id).then((data) => setJobOfferContent(data))
        }
    }, [])




    const handleEditorChange = (content, editor) => {
        handleJobOfferChange("content", content)
    }

    const handleJobOfferChange = (field, value) => {
        setJobOfferContent({ ...jobOfferContent, [field]: value })
    }

    const handleJobOfferCreation = async () => {
        try {
            if (!jobOfferContent.team) {
                toast({
                    title: 'Veuillez choisir une team',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                return;
            }
            if (jobOfferContent.publicationDate > jobOfferContent.expirationDate && jobOfferContent.expirationDate != "" && jobOfferContent.expirationDate) {
                toast({
                    title: 'La date de publication doit être inférieure à la date d\'expiration',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                return;
            }
            const data = await createJobOffer(id, jobOfferContent);

            navigate("/paul-emploi/update/" + data._id);
            toast({
                title: 'Offre d\'emploi sauvegardée',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

        } catch (error) {
            console.error('Error creating job offer:', error.message);
        }
    }

    const handleTags = () => {
        if (newTag === "") return;

        let { tags } = jobOfferContent;
        tags.push(newTag)
        handleJobOfferChange("tags", tags);
        setNewTag("");
    }


    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <Button onClick={() => navigate("/paul-emploi")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux offres d'emplois</Button>
                </Flex>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={4} color={"gray.700"}>{id ? "Modification  " : "Création"} d'une offre d'emploi</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <Box boxShadow={"base"} p={4}>
                    <Grid templateColumns="repeat(5, 1fr)" gap={4} mb={4}>
                        <FormControl flex={1}>
                            <FormLabel>Nom</FormLabel>
                            <Input type='text' placeholder='Développeur javascript fullstack' value={jobOfferContent.name} onChange={(e) => handleJobOfferChange("name", e.target.value)} />
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Choix de l'équipe</FormLabel>
                            <Select placeholder='Sélectionner une équipe' onChange={(e) => handleJobOfferChange("team", e.target.value)} value={jobOfferContent.team?._id ?? undefined}>
                                {user.teams && user.teams.map((element, index) => (
                                    <option value={element._id} key={index}>{element.name}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Lieu</FormLabel>
                            <Input type='text' placeholder='Paris 09' value={jobOfferContent.location} onChange={(e) => handleJobOfferChange("location", e.target.value)} />
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Salaire</FormLabel>
                            <InputGroup>
                                <Input type='number' placeholder='Salaire minimum' value={jobOfferContent?.salary?.[0] ?? undefined} onChange={(e) => { const salary = jobOfferContent.salary; salary[0] = parseInt(e.target.value); handleJobOfferChange("salary", salary) }} />
                                <Input type='number' placeholder='Salaire maximum' value={jobOfferContent?.salary?.[1] ?? undefined} onChange={(e) => { const salary = jobOfferContent.salary; salary[1] = parseInt(e.target.value); handleJobOfferChange("salary", salary) }} />
                            </InputGroup>
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Type de contrat</FormLabel>
                            <Select placeholder='Sélectionner un type de contrat' onChange={(e) => handleJobOfferChange("contract_type", e.target.value)} value={jobOfferContent?.contract_type ?? undefined}>
                                <option value={"CDI"} >CDI</option>
                                <option value={"CDD"} >CDD</option>
                                <option value={"Stage"} >Stage</option>
                                <option value={"Alternance"} >Alternance</option>
                            </Select>
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Type de poste</FormLabel>
                            <Select placeholder='Sélectionner un type de poste' onChange={(e) => handleJobOfferChange("contract_time", e.target.value)} value={jobOfferContent?.contract_time ?? undefined}>
                                <option value={"Temps partiel"} >Temps partiel</option>
                                <option value={"Temps complet"} >Temps complet</option>
                            </Select>
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Date de publication de l'offre</FormLabel>
                            <Input type="datetime-local" required value={jobOfferContent.publicationDate ? formatDateForInput(jobOfferContent.publicationDate) : undefined} onChange={(e) => handleJobOfferChange("publicationDate", e.target.value)} />
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Date de fin de l'offre</FormLabel>
                            <Input type="datetime-local" value={jobOfferContent.expirationDate ? formatDateForInput(jobOfferContent.expirationDate) : undefined} onChange={(e) => handleJobOfferChange("expirationDate", e.target.value)} />
                        </FormControl>
                        <FormControl flex={3}>
                            <FormLabel>Compétences associées</FormLabel>
                            <Flex alignItems="center" gap={4} w={"100%"}>
                                <InputGroup width={"md"}>
                                    <Input
                                        type={'text'}
                                        placeholder='Javascript, Excel, SCRUM, etc...'
                                        value={newTag}
                                        onChange={(e) => setNewTag(e.target.value)}
                                        onKeyDown={(e) => onKeyPressEnter(e, handleTags)}
                                    />
                                    <InputRightElement width='4.5rem' pr={1}>
                                        <Button size='xs' onClick={handleTags}>
                                            Ajouter
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <Flex align="center" gap={1}>
                                    {jobOfferContent.tags && jobOfferContent.tags.map((tag, index) => <Tag
                                        key={index}
                                        variant='solid'
                                        colorScheme='teal'
                                    >
                                        <TagLabel>{tag}</TagLabel>
                                        <TagCloseButton onClick={() => handleJobOfferChange("tags", [...jobOfferContent.tags.slice(0, index), ...jobOfferContent.tags.slice(index + 1)])} />
                                    </Tag>)}
                                </Flex>
                            </Flex>
                        </FormControl>

                    </Grid>
                    <Flex direction="column" gap={4}>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={jobOfferContent.description}
                                onChange={(e) => handleJobOfferChange("description", e.target.value)}
                                placeholder="Description"
                                size="sm"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Contenu</FormLabel>
                            <CreateJobOfferWysiwygComponent handleEditorChange={handleEditorChange} defaultValue={jobOfferContent.content} />
                        </FormControl>
                    </Flex>
                    <Flex mt={4} justifyContent="flex-end">
                        <Button onClick={handleJobOfferCreation} size="sm" leftIcon={<FaSave />} colorScheme="teal">Sauvegarder l'offre d'emploi</Button>
                    </Flex>
                </Box>
            </Container>
        </>
    )
}

export default JobOfferUpdateComponent