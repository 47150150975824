import React, { useState, useEffect } from 'react';
import { Image } from '@chakra-ui/react'
import { API_URL } from "../keys";

const ImageFromS3 = ({ imageKey }) => {
    const [imageUrl, setImageUrl] = useState('');
    if (imageKey.charAt(0) == "/") imageKey = imageKey.substr(1);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                // Requête GET pour récupérer l'image depuis le backend
                const response = await fetch(API_URL + '/s3/get', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ key: imageKey })
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération de l\'image');
                }

                // Convertir la réponse en blob (binary data)
                const imageBlob = await response.blob();

                // Crée une URL temporaire à partir du blob pour afficher l'image
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImageUrl(imageObjectURL);
            } catch (error) {
                console.error("Erreur lors de la récupération de l'image:", error);
            }
        };

        fetchImage();
    }, [imageKey]); // Re-fetch l'image si imageKey change

    return (
        <div>
            {imageUrl ? (
                <Image width={"300px"} objectFit={"cover"} src={imageUrl} mb={4} />
            ) : (
                <p>Chargement de l'image...</p>
            )}
        </div>
    );
};

export default ImageFromS3;
