import React, { useState, useEffect } from 'react'
import LoginForm from '../Forms/LoginForm'
import { Navigate } from 'react-router-dom'
import { getUserInfos } from "../API/Users";

const LoginComponent = () => {
  const [user, setUser] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      const loadedUser = await getUserInfos();
      setUser(loadedUser);
    };

    loadData();

  }, [])

  return (
    <>
      {user.email ?
        <Navigate to='/' replace={true} />
        :
        <LoginForm />
      }
    </>
  )
}

export default LoginComponent