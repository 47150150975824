import { API_URL } from "../keys";

export const getTeamsByUser = async () => {
    const response = await fetch(API_URL + '/teams/getteamsbyuser', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    return await response.json();
}