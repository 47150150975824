import { Box, Button, Card, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Heading, IconButton, Input, Table, TableContainer, Tag, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { API_URL } from "../keys";
import { FaKey, FaTrash } from 'react-icons/fa6';


const ProfileUpdateTeam = ({ isVisible, onClose, team, role, reload }) => {
    const [teamInformations, setTeamInformations] = useState(null);
    const [memberInput, setMemberInput] = useState("")
    const [isUpdate, setIsUpdate] = useState(false)

    const loadTeamInformations = async () => {
        try {
            const response = await fetch(
                API_URL + '/teams/' + team.id,
                {
                    method: 'GET',
                    credentials: 'include'
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setTeamInformations(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const addMember = async (member_id) => {
        try {
            const response = await fetch(`${API_URL}/teams/${team.id}/member/${member_id}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            setMemberInput("");
            loadTeamInformations();
            reload();

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const promoteMember = async (member_id) => {
        try {
            const response = await fetch(`${API_URL}/teams/${team.id}/promote/${member_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            loadTeamInformations();
            reload();

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const deleteMember = async (member_id) => {
        try {
            const response = await fetch(`${API_URL}/teams/${team.id}/member/${member_id}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            loadTeamInformations();
            reload();

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const udapteTeamInformations = async () => {
        let { name, description } = teamInformations
        try {
            const response = await fetch(`${API_URL}/teams/${team.id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, description })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            loadTeamInformations();
            reload();
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    useEffect(() => {
        if (team !== null) {
            loadTeamInformations()
        }

    }, [team])

    const getTagByRole = (role) => {
        if (role === "member") return "Membre"
        else if (role === "administrator") return "Administrateur"
        else if (role === "candidate") return "Candidat"
    }

    if (!team || !teamInformations) return null

    return (
        <Drawer
            isOpen={isVisible}
            placement='right'
            onClose={onClose}
            size={"lg"}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>
                    Informations
                </DrawerHeader>
                <DrawerBody p={2} display={"flex"} flexDirection={"column"} gap={2}>
                    <Card p={4}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <Flex justifyContent={"space-between"}>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase' pb={0}>
                                            Nom de l'équipe
                                        </Heading>
                                        {isUpdate ? <Input mt={1} size={"sm"} value={teamInformations.name} onChange={e => setTeamInformations({ ...teamInformations, name: e.target.value })} /> :
                                            <Text fontSize='sm'>
                                                {teamInformations && teamInformations.name}
                                            </Text>
                                        }
                                    </Box>
                                    <Box>
                                        <Button size="sm" onClick={() => setIsUpdate(!isUpdate)}>{isUpdate ? "Passer en mode lecture" : "Passer en mode édition"}</Button>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                    Description
                                </Heading>
                                {isUpdate ? <Textarea mt={1} size={"sm"} value={teamInformations.description} onChange={e => setTeamInformations({ ...teamInformations, description: e.target.value })} /> :
                                    <Text fontSize='sm'>
                                        {teamInformations && teamInformations.description}
                                    </Text>
                                }
                            </Box>
                            {isUpdate ? <Box textAlign={'right'}>
                                <Button colorScheme='teal' size="sm" onClick={() => udapteTeamInformations()}>Enregistrer</Button>
                            </Box> : null}
                            <Box>
                                <Heading size='xs' textTransform='uppercase' mb={2}>
                                    Votre rôle dans l'équipe
                                </Heading>
                                <Text fontSize='sm'>
                                    <Tag bg={team.role === "administrator" ? 'gray.600' : 'blue.400'} color="white">{getTagByRole(team.role)}</Tag>
                                </Text>
                            </Box>
                        </Flex>
                    </Card>
                    <Card p={4}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <Heading size='xs' textTransform='uppercase' pb={4}>
                                    Administrateur
                                </Heading>
                                <TableContainer>
                                    <Table size='sm'>
                                        <Thead>
                                            <Tr>
                                                <Th>Nom</Th>
                                                <Th>Prénom</Th>
                                                <Th>Email</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {teamInformations && teamInformations.administrator.map((administrator, index) => <Tr key={index}>
                                                <Td>{administrator.lastname}</Td>
                                                <Td>{administrator.firstname}</Td>
                                                <Td>{administrator.email}</Td>
                                            </Tr>)}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Flex>
                    </Card>
                    <Card p={4}>
                        <Flex gap={4} direction={"column"}>
                            <Box>
                                <Heading size='xs' textTransform='uppercase' pb={4}>
                                    Membre
                                </Heading>
                                <TableContainer>
                                    <Table size='sm'>
                                        <Thead>
                                            <Tr>
                                                <Th>Nom</Th>
                                                <Th>Prénom</Th>
                                                <Th>Email</Th>

                                                {isUpdate ? <Th>Action</Th> : null}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {teamInformations && teamInformations.member.map((member, index) => <Tr key={index}>
                                                <Td>{member.lastname}</Td>
                                                <Td>{member.firstname}</Td>
                                                <Td>{member.email}</Td>
                                                {isUpdate ? <Td display={"flex"} gap={1}>
                                                    <Tooltip label="Promouvoir en tant qu'administrateur">
                                                        <IconButton size={"xs"} icon={<FaKey />} colorScheme='teal' onClick={() => promoteMember(member._id)} />
                                                    </Tooltip>
                                                    <Tooltip label="Supprimer le candidat de l'équipe">
                                                        <IconButton size={"xs"} icon={<FaTrash />} colorScheme='red' onClick={() => deleteMember(member._id)} />
                                                    </Tooltip>
                                                </Td> : null}
                                            </Tr>)}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            {isUpdate ? <Box display="flex" gap={1}>
                                <Input value={memberInput} onChange={e => setMemberInput(e.target.value)} placeholder='UUID utilisateur' size='sm' flex={3} /> <Button onClick={() => addMember(memberInput)} flex={1} size="sm">Ajouter le membre</Button>
                            </Box> : null}
                        </Flex>
                    </Card>

                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default ProfileUpdateTeam