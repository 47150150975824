import React, { useEffect, useRef, useState } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    TableContainer,
    IconButton,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Flex,
    Box,
    Image,
    Text,
    Spinner
} from '@chakra-ui/react'
import { FaTrash, FaPenToSquare, FaEye } from "react-icons/fa6";

import { useNavigate } from "react-router-dom"
import { convertTypeToLabel } from '../Utils/QuestionnairesUtils';
import EmptyQuestionnaires from "../Resources/Images/empty_questionnaires.svg";
import { removeQuestionnaire } from '../API/Questionnaires';



const QuestionnairesTable = ({ loading, questionnaires, reload }) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)


    useEffect(() => {
    }, [questionnaires])

    const convertPermissionToLabel = (permission) => {
        if (permission === "own") {
            return "Propriétaire"
        } else if (permission === "write") {
            return "Écriture"
        } else if (permission === "read") {
            return "Lecture"
        } else {
            return "Aucune"
        }
    }

    const getBadgeFromTags = (tags) => {
        return tags.map((tag, index) => (
            <Badge key={index} mr={1}>{tag}</Badge>
        ))
    }

    const getActionsFromPermission = (permission, id) => {
        if (permission === "own" || permission === "write") {
            return <>
                <IconButton
                    aria-label='Read'
                    icon={<FaEye />}
                    mr={1}
                    onClick={() => navigate(`/questionnaire/${id}`)}
                />
                <IconButton
                    aria-label='Update'
                    colorScheme='teal'
                    icon={<FaPenToSquare />}
                    onClick={() => navigate(`/questionnaire/update/${id}`)}
                    mr={1}
                />
                <IconButton
                    onClick={() => {
                        setToDeletion(id)
                        onOpen()
                    }}
                    colorScheme='red'
                    aria-label='Delete'
                    icon={<FaTrash />}
                />
            </>
        } else if (permission === "read") {
            return <IconButton
                aria-label='Read'
                icon={<FaEye />}
                mr={1}
                onClick={() => navigate(`/questionnaire/${id}`)}
            />
        } else {
            return null
        }
    }

    const handleDeletion = async () => {
        try {
            const response = await removeQuestionnaire(toDeletion);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                reload()

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return questionnaires.length === 0 ? <Flex justifyContent={"center"}>
        {loading ?
            <TableContainer width={"100%"} >
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Description</Th>
                            <Th>Permission</Th>
                            <Th>Nombre de questions</Th>
                            <Th>Compétences</Th>
                            <Th>Type</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td colSpan={7} textAlign={"center"}>
                                <Spinner />
                                <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Import des questionnaires en cours...</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            :
            <Box p={4}>
                <Image src={EmptyQuestionnaires} alt="No questionnaires" maxHeight={500} />
                <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Vos questionnaires apparaîtrons ici !</Text>
            </Box>
        }
    </Flex>
        :
        <>
            <TableContainer width={"100%"} >
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Description</Th>
                            <Th>Permission</Th>
                            <Th>Nombre de questions</Th>
                            <Th>Compétences</Th>
                            <Th>Type</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {loading ?
                            <Tr>
                                <Td colSpan={7} textAlign={"center"}>
                                    <Spinner />
                                    <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Import des questionnaires en cours...</Text>
                                </Td>
                            </Tr>
                            :
                            questionnaires.map((questionnaire, index) => (
                                <Tr key={index}>
                                    <Td>{questionnaire.questionnaire.name}</Td>
                                    <Td>{questionnaire.questionnaire.description}</Td>
                                    <Td>{convertPermissionToLabel(questionnaire.permissions)}</Td>
                                    <Td>{questionnaire.questionnaire.questions.length}</Td>
                                    <Td>{getBadgeFromTags(questionnaire.questionnaire.tags)}</Td>
                                    <Td>{convertTypeToLabel(questionnaire.questionnaire.type)}</Td>
                                    <Td>{getActionsFromPermission(questionnaire.permissions, questionnaire.questionnaire._id)}</Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le questionnaire <b>{toDeletion}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
}

export default QuestionnairesTable