import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Container, Button, Flex, Card } from '@chakra-ui/react';
import JobOfferHeader from '../Components/JobOfferHeader';
// import QuestionnairesQuestion from '../Components/QuestionnairesQuestion';
import { FaArrowLeft } from "react-icons/fa6";
import { getJobOfferById } from "../API/JobOffers";

const JobOfferComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [jobOffer, setJobOffer] = useState({})

    useEffect(() => {
        getJobOfferById(id).then((data) => setJobOffer(data))
    }, [])

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <Button onClick={() => navigate("/paul-emploi")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux offres d'emploi</Button>
                </Flex>
                <Flex direction="column" gap={4} mb={12}>
                    <JobOfferHeader {...jobOffer} />
                    <Card p={12}>
                        <span dangerouslySetInnerHTML={{ __html: jobOffer?.content }} />
                    </Card>
                </Flex>
            </Container>
        </>
    )
}

export default JobOfferComponent