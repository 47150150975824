import React, { useEffect, useState } from 'react'
import { Badge, Box, Button, CircularProgress, CircularProgressLabel, Container, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import EmptyRunningQuestionnaireCard from '../Components/EmptyRunningQuestionnaireCard';
import { FaFileSignature } from 'react-icons/fa';
import EmptyDoneQuestionnaireCard from '../Components/EmptyDoneQuestionnaireCard';
import { FaChevronRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../keys";

const CandidatHomepage = () => {
    const [candidateRunningQuestionnaire, setCandidateRunningQuestionnaire] = useState(null);
    const [candidateDoneQuestionnaireRouter, setCandidateDoneQuestionnaireRouter] = useState(null)
    const navigate = useNavigate();

    const fetchCandidateRunningQuestionnaire = async () => {
        try {
            const response = await fetch(`${API_URL}/candidate_running_questionnaire/` + "self", {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setCandidateRunningQuestionnaire(data)
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    const fetchCandidateDoneQuestionnaire = async () => {
        try {
            const response = await fetch(`${API_URL}/candidate_done_questionnaire/` + "self", {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setCandidateDoneQuestionnaireRouter(data)
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    useEffect(() => {
        fetchCandidateRunningQuestionnaire();
        fetchCandidateDoneQuestionnaire();
    }, [])

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={4} color={"gray.700"}>Espace candidat</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <Box w='100%' my={4}>
                    <Heading mb={4} size={"lg"} color={"gray.700"}>Questionnaires à faire</Heading>
                    <Grid gridTemplateColumns="repeat(6, 1fr)" gap={4}>
                        {candidateRunningQuestionnaire && candidateRunningQuestionnaire.length == 0 ? <EmptyRunningQuestionnaireCard isCandidate={true} /> : null}
                        {candidateRunningQuestionnaire && candidateRunningQuestionnaire.map((infos, index) => {
                            let questionnaire = infos.questionnaire_id
                            return <Flex boxShadow={"base"} p={4} key={index + "-runnis-questionnaire"} direction={"column"}>

                                <Flex justifyContent={"space-between"}>
                                    <Text as="b" fontSize='xl'>{questionnaire.name}</Text>
                                </Flex>
                                <Text mt={4} fontSize='md'>{questionnaire.description}</Text>
                                <Box mt={4}>
                                    {questionnaire.tags.map((tag, index) => (
                                        <Badge key={"tag-" + index} mr={1}>{tag}</Badge>
                                    ))}
                                </Box>
                                <Box textAlign={"right"} my={4}>
                                    <Text as="i"><b>{questionnaire.questions.length}</b> questions</Text>
                                </Box>
                                <Flex mt={"auto"}>
                                    <Button colorScheme='teal' rightIcon={<FaChevronRight />} flex={1} verticalAlign={"baseline"} onClick={() => navigate("/exam/" + questionnaire._id)}>Commencer le questionnaire</Button>
                                </Flex>
                            </Flex>
                        })}
                    </Grid>
                </Box>
                <Box w='100%' my={4}>
                    <Heading mb={4} size={"lg"} color={"gray.700"}>Questionnaires réalisés</Heading>
                    <Grid gridTemplateColumns="repeat(4, 1fr)" gap={4}>
                        {candidateDoneQuestionnaireRouter && candidateDoneQuestionnaireRouter.length == 0 ? <EmptyDoneQuestionnaireCard /> : null}
                        {candidateDoneQuestionnaireRouter && candidateDoneQuestionnaireRouter.map((questionnaire, index) => {
                            return <Flex key={index + "-questionnaire"} boxShadow={"base"} p={4} direction="column">
                                <Flex alignItems="center" justifyContent={"space-between"}>
                                    <Flex direction={"column"}>
                                        <Text as="b" fontSize='xl'>{questionnaire.name}</Text>
                                        <Text fontSize='md'>{questionnaire.description}</Text>
                                    </Flex>
                                    <Box>
                                        <CircularProgress value={(questionnaire.good / questionnaire.questions.length) * 100} color='green.400'>
                                            <CircularProgressLabel>{Math.round((questionnaire.good / questionnaire.questions.length) * 100)}%</CircularProgressLabel>
                                        </CircularProgress>
                                    </Box>
                                </Flex>
                            </Flex>
                        })}
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default CandidatHomepage