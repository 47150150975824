import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { API_URL } from "../keys";
import { useToast } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom"

const GoogleAuth = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const toast = useToast()
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('code')) {
            fetch(API_URL + '/auth/google/callback', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: searchParams.get('code'),
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    toast({
                        title: 'Connexion reussie',
                        description: "Nous avons pu vous connecter à Google.",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                        position: "top"
                    })
                })
                .catch((error) => {
                    toast({
                        title: 'Cheh',
                        description: "Nous n'avons pas pu vous connecter à Google.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                        position: "top"
                    })
                    console.error('Error:', error);
                })
                .finally(() => {
                    navigate('/questionnaires')
                });
        }
    })
}

export default GoogleAuth;
