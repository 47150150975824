import { Box, Checkbox, Code, Flex, Tag, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { GetLabelFromType } from "../Utils/QuestionnairesUtils";
import ImageFromS3 from '../Utils/ImageFromS3';
const ExamResultQuestionCard = ({ question, questionIndex, candidateAnswers, questionnaireAnswers }) => {
    const checkColorDependingToResponse = (acceptedAnswers, userAnswer, answerIndex) => {
        const answer = userAnswer.find(el => parseInt(el.value) === answerIndex);

        if (answer) {
            return answer.good ? "green" : "red"
        }
        if (acceptedAnswers.includes(answerIndex)) {
            return "red"
        }

    }

    const checkLabelDependingToResponse = (acceptedAnswers, userAnswer, answerIndex) => {
        const answer = userAnswer.find(el => parseInt(el.value) === answerIndex);
        if (answer) {
            return answer.good ? " - Le candidat a coché cette bonne réponse" : " - Le candidat a coché cette mauvaise réponse"
        }
        if (acceptedAnswers.includes(answerIndex)) {
            return " - Le candidat n'a pas coché cette bonne réponse"
        }
    }

    return (
        <Box w='100%' boxShadow={"base"} p={4} color="gray.700" key={"question" + questionIndex}>
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='2xl'>{question.description}</Text>
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType(question.type)}</Tag>
            </Flex>
            {question.image && <ImageFromS3 imageKey={question.image} />}
            {/* TODO: Un component par type de question */}
            {question.type !== "short" ? question.responses.map((res, index) => (
                <Flex gap={4} key={"response" + index}>
                    <Text fontSize='md'>{String.fromCharCode(65 + index)} - <Code colorScheme={checkColorDependingToResponse(questionnaireAnswers?.acceptedAnswer ?? null, candidateAnswers, index)}>{res}</Code>{checkLabelDependingToResponse(questionnaireAnswers?.acceptedAnswer ?? null, candidateAnswers, index)}</Text>
                </Flex>
            )) :
                <Flex gap={4} >
                    <Text fontSize='md'>
                        {console.log(question)
                        }
                        A - <Code colorScheme={candidateAnswers[0]?.good ? "green" : "red"}>{candidateAnswers[0]?.value}</Code> {!candidateAnswers[0]?.good ? '- Cette réponse à été jugé fausse ou trop approximative' : null}
                        {question.responses[0] &&
                            <Text>
                                Réponse suggérée : {question.responses[0]}
                            </Text>
                        }
                    </Text>
                </Flex>
            }
        </Box >
    )
}

export default ExamResultQuestionCard