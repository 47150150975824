import { API_URL } from '../keys';

export const getJobOfferById = async (jobOfferId) => {
    const response = await fetch(API_URL + "/joboffers/" + jobOfferId, {
        method: 'GET',
        credentials: 'include',
    })
    const data = await response.json();

    return data;
}

export const createJobOffer = async (id, jobOffer) => {
    try {

        const response = await fetch(`${API_URL}/joboffers${id ? "/" + id : ""}`, {
            method: id ? 'PUT' : 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jobOffer)
        })
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error creating job offer:', error.message);
    }
}

export const removeJobOffer = async (jobOfferId) => {
    const response = await fetch(API_URL + "/joboffers/" + jobOfferId, {
        method: 'DELETE',
        credentials: 'include',
    })

    return response.ok ? true : false;
}
