import { API_URL } from "../keys";

export const removeQuestionnaire = async (id) => {
    const response = await fetch(API_URL + '/questionnaires/' + id, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    return response;
}