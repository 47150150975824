import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

export const formatDateForInput = (date) => {
    return moment(date).subtract(2, 'hours').format('YYYY-MM-DDTHH:mm');
}

export const formatDateForPrint = (date) => {
    return moment(date).subtract(2, 'hours').format('LLL');
}

export const displayOfferEndMessage = (date) => {
    const offerEndDate = new Date(date);
    const now = new Date();

    const timeRemaining = offerEndDate - now;

    if (timeRemaining <= 0) {
        return "L'offre est terminée";
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);  // Approximation: 1 month = 30 days

    let message;
    if (months > 0) {
        message = `Fin de l'offre dans ${months} mois.`;
    } else {
        message = `Fin de l'offre dans ${days} jours.`;
    }

    return message
}