import React, { useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom'

import { FaArrowLeft, FaPlus, FaSave, FaTrash } from 'react-icons/fa'
import { Box, Button, Checkbox, Container, Flex, FormControl, FormLabel, Heading, IconButton, Image, Input, InputGroup, InputRightElement, Radio, RadioGroup, Select, Stack, Tag, TagCloseButton, TagLabel, Textarea, useToast } from '@chakra-ui/react'

import { API_URL } from "../keys";
import ImageFromS3 from "../Utils/ImageFromS3";
import onKeyPressEnter from '../Utils/OnKeyPressEnter'

//TODO: Refaire toute la page mais pas avec une seule variable initalValues
// Plutot avoir une partie questionnaire, une partie questions, une partie answers, une partie communiste
const QuestionnaireUpdateComponent = ({ }) => {

    const { id } = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const [newTag, setNewTag] = useState("")
    const [newAccessTeam, setNewAccessTeam] = useState("")
    const [newModifyTeam, setNewModifyTeam] = useState("")
    const [newAnswer, setNewAnswer] = useState({})
    const [newQuestion, setNewQuestion] = useState("")
    const [teams, setTeams] = useState(null)
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();
    const showRadio = ['multiple', 'unique'];

    const fetchQuestionnaire = async (questionnaireId) => {
        try {
            const response = await fetch(`${API_URL}/questionnaires/getuserquestionnaire/${questionnaireId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setInitialValues(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    };

    const fetchUserTeam = async () => {
        try {
            const response = await fetch(API_URL + '/teams/getteamsbyuser', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            setTeams(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    useEffect(() => {
        fetchQuestionnaire(id);
        fetchUserTeam()
    }, [])

    const convertToFormData = (data) => {
        const formData = new FormData();
        // Ajouter les champs de base à formData
        formData.append('name', data.questionnaire.name);
        formData.append('description', data.questionnaire.description);
        formData.append('type', data.questionnaire.type);
        if ((data.questionnaire._id || id) && id !== 'new') {
            formData.append('_id', data.questionnaire._id ?? id);
        }

        data.questionnaire.accessTeam.forEach(team => {
            formData.append('accessTeam', team);
        });

        data.questionnaire.modifyTeam.forEach(team => {
            formData.append('modifyTeam', team);
        });

        data.questionnaire.tags.forEach(tag => {
            formData.append('tags', tag);
        });

        // Ajouter les questions et réponses
        data.questionnaire.questions.forEach((question, index) => {
            formData.append(`questions[${index}][_id]`, question._id);
            formData.append(`questions[${index}][description]`, question.description);
            formData.append(`questions[${index}][type]`, question.type);
            formData.append(`questions[${index}][index]`, question.index);
            if (typeof question.image === 'object') {
                formData.append(`questions[${index}][image]`, question.image);
            }

            // Ajouter les réponses
            question.responses.forEach((response, responseIndex) => {
                formData.append(`questions[${index}][responses][${responseIndex}]`, response);
            });

            // Ajouter l'image si elle est présente
            if (question.image && typeof question.image !== 'string') {
                formData.append(`questions[${index}][image]`, question.image); // Ici, question.image doit être un fichier
            }
        });

        // Ajouter les autres propriétés
        formData.append('teamOwner', data.questionnaire.teamOwner);
        formData.append('permissions', data.permissions);

        // Ajouter les réponses fournies
        Object.keys(data.questionsAnswers).forEach((questionId) => {
            data.questionsAnswers[questionId].forEach(answer => {
                formData.append(`questionsAnswers[${questionId}]`, answer);
            })
        });

        return formData;
    };

    const handleTeam = async (team, newTeam, setTeam, teamName) => {
        if (newTeam.length <= 0) {
            return;
        }
        try {
            const response = await fetch(`${API_URL}/teams/${newTeam}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                toast({
                    title: "L'équipe n'existe pas.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }

            if (!team.includes(newTeam)) {
                team.push(newTeam)
                handleQuestionnaireChanges(teamName, team);
                setTeam("");
            } else {
                toast({
                    title: "L'équipe existe déja.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
            toast({
                title: "L'équipe n'existe pas.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    }

    const handleNewAccessTeam = async () => {
        let { accessTeam } = initialValues.questionnaire;
        await handleTeam(accessTeam, newAccessTeam, setNewAccessTeam, "accessTeam");
    }

    const handleNewModifyTeam = async () => {
        let { modifyTeam } = initialValues.questionnaire;
        await handleTeam(modifyTeam, newModifyTeam, setNewModifyTeam, "modifyTeam");
    }

    const handleNewTag = () => {
        let { tags } = initialValues.questionnaire;
        if (newTag.length > 0) {
            tags.push(newTag)
            handleQuestionnaireChanges("tags", tags);
            setNewTag("");
        }
    }

    const handleQuestionnaireChanges = (field, value) => {
        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, [field]: value } })
    }

    const handleQuestionChanges = (index, field, value, responseIndex = null) => {
        let { questions } = initialValues.questionnaire;

        questions[index][field] = value;
        // C'est degeu mais ca permet de supprimer la reponse d'une question quand on supprime la question
        if (responseIndex) {
            const questionId = questions[index]._id;
            let acceptedAnswer = initialValues.questionsAnswers[questionId];
            acceptedAnswer = acceptedAnswer.filter(el => el != responseIndex)
            return setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, questions: questions }, questionsAnswers: { ...initialValues.questionsAnswers, [questionId]: acceptedAnswer } })
        }

        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, questions: questions } })
    }

    const handleQuestionResponseChanges = (questionIndex, responseIndex, value) => {
        let { questions } = initialValues.questionnaire;
        questions[questionIndex]["responses"][responseIndex] = value;

        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, questions: questions } })
    }

    const handleQuestionAnswersChange = (question_id, answer_index, isChecked) => {
        let acceptedAnswer = initialValues.questionsAnswers[question_id];
        if (!acceptedAnswer) {
            acceptedAnswer = []
        }

        if (isChecked) {
            if (!acceptedAnswer.includes(answer_index)) {
                acceptedAnswer.push(answer_index)
            }
        } else {
            acceptedAnswer = acceptedAnswer.filter(el => el != answer_index)
        }

        setInitialValues({ ...initialValues, questionsAnswers: { ...initialValues.questionsAnswers, [question_id]: acceptedAnswer } })
    }

    const handleImageChange = (questionIndex, image) => {
        let { questions } = initialValues.questionnaire;
        questions[questionIndex]["image"] = image;
        questions[questionIndex]["tmp"] = URL.createObjectURL(image);
        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, questions: questions } })
    }

    const handleSaveQuestionnaire = async () => {
        if (!initialValues.questionnaire._id && initialValues.questionnaire.teamOwner === null)
            initialValues.questionnaire.teamOwner = teams[0]._id

        if (!initialValues.questionnaire._id && initialValues.questionnaire.type === "")
            initialValues.questionnaire.type = 'public'

        try {
            setLoading(true);
            const formData = convertToFormData(initialValues);

            const response = await fetch(API_URL + '/questionnaires/updatebyid', {
                method: 'POST',
                credentials: 'include',
                body: formData
            })

            if (!response.ok) {
                setLoading(false);
                throw new Error('Network response was not ok');
            }
            const { updatedQuestionnaire } = await response.json();

            navigate(`/questionnaire/update/${updatedQuestionnaire._id}`);

            toast({
                title: 'Modification du questionnaire',
                description: "Le server a bien pris en compte les modifications.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error updating questionnaire:', error);
            toast({
                title: 'Modification du questionnaire',
                description: "Le serveur n'a pas réussi à mettre à jour le questionnaire",
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        }
    }

    const generateUuid = () => {
        const uuid = uuidv4().replace(/-/g, ''); // Remove dashes
        return uuid.slice(0, 24); // Trim to 24 characters
    }

    if (!initialValues)
        return "waiting..."

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' mb={4}>
                    <Button onClick={() => navigate("/questionnaires")} p={0} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux questionnaires</Button>
                </Flex>
                <Box>
                    <Flex

                        position="sticky"
                        top={0}
                        bg="white"
                        py={4}
                        borderBottom='2px'
                        borderColor='teal'
                        zIndex="10"
                        alignItems="center"
                        justifyContent="space-between"
                        direction={{ base: "column", md: "row" }}
                    >
                        <Heading textAlign={{ base: "center", md: "left" }}>{id === "new" ? "Création " : "Modification"} de questionnaire</Heading>
                        <Flex>
                            <Button leftIcon={<FaSave />} isLoading={loading} disabled={loading} onClick={handleSaveQuestionnaire}>Enregistrer le questionnaire</Button>
                        </Flex>
                    </Flex>
                    <Flex direction={{ base: "column", lg: "row" }} gap={4} mt={4} display="grid" gridTemplateColumns={{ base: "1fr", lg: "25% 74%" }}>
                        <Flex direction="column" gap={4} boxShadow={"base"} p={4} borderRadius="md">
                            <Heading>Informations générales</Heading>
                            <FormControl>
                                <FormLabel>Nom</FormLabel>
                                <Input type='text' value={initialValues.questionnaire.name} onChange={(e) => handleQuestionnaireChanges('name', e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea height={{ base: 150, md: 215 }} value={initialValues.questionnaire.description} onChange={(e) => handleQuestionnaireChanges('description', e.target.value)} />
                            </FormControl>
                        </Flex>
                        <Flex direction="column" gap={4}>
                            <Flex flex={1} direction="column" gap={4} boxShadow={"base"} p={4} borderRadius="md">
                                <Heading>Configuration</Heading>
                                <Flex gap={4} direction={{ base: "column", lg: "row" }} flexWrap="wrap">
                                    <FormControl flex={1}>
                                        <FormLabel>Visibilité</FormLabel>

                                        <RadioGroup value={initialValues.questionnaire.type || 'public'} onChange={(e) => handleQuestionnaireChanges('type', e)} >
                                            <Stack spacing={4} direction='row'>
                                                <Radio colorScheme='green' value='public'>
                                                    Publique
                                                </Radio>
                                                <Radio colorScheme='green' value='private'>
                                                    Privée
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl flex={1}>
                                        <FormLabel>Équipe propriétaire</FormLabel>
                                        <Select disabled={id !== "new"} spacing={5} value={initialValues.questionnaire.teamOwner || ""} onChange={(e) => handleQuestionnaireChanges('teamOwner', e.target.value)}>
                                            {teams && teams.length > 0 && teams.map((team, index) => <option key={"team" + index} value={team._id}>{team.name}</option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl flex={4}>
                                        <FormLabel>Compétences associées</FormLabel>
                                        <InputGroup mb={4}>
                                            <Input
                                                type={'text'}
                                                placeholder='Javascript, Excel, SCRUM, etc...'
                                                value={newTag}
                                                onChange={(e) => setNewTag(e.target.value)}
                                                onKeyDown={(e) => onKeyPressEnter(e, handleNewTag)}
                                            />
                                            <InputRightElement width='4.5rem' pr={1}>
                                                <Button size='xs' onClick={handleNewTag}>
                                                    Ajouter
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Flex align="center" gap={1}>
                                            {initialValues.questionnaire.tags.map((tag, index) => <Tag
                                                key={index}
                                                variant='solid'
                                                colorScheme='teal'
                                            >
                                                <TagLabel>{tag}</TagLabel>
                                                <TagCloseButton onClick={() => handleQuestionnaireChanges("tags", [...initialValues.questionnaire.tags.slice(0, index), ...initialValues.questionnaire.tags.slice(index + 1)])} />
                                            </Tag>)}
                                        </Flex>
                                    </FormControl>
                                </Flex>
                            </Flex>
                            <Flex flex={1} direction="column" gap={4} boxShadow={"base"} p={4} borderRadius="md">
                                <Heading>Partage</Heading>
                                <Flex gap={5} justifyContent="flex-start" direction={{ base: "column", lg: "row" }}>
                                    <FormControl>
                                        <FormLabel>Équipe en lecture</FormLabel>
                                        <InputGroup mb={4}>
                                            <Input
                                                type={'text'}
                                                value={newAccessTeam}
                                                onChange={(e) => setNewAccessTeam(e.target.value)}
                                                onKeyDown={(e) => onKeyPressEnter(e, handleNewAccessTeam)}
                                            />
                                            <InputRightElement width='4.5rem' pr={1}>
                                                <Button size='xs' onClick={handleNewAccessTeam}>
                                                    Ajouter
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Flex align="center" gap={1}>
                                            {initialValues.questionnaire.accessTeam.map((team, index) => <Tag
                                                key={index}
                                                variant='solid'
                                                colorScheme='teal'
                                            >
                                                <TagLabel>{team}</TagLabel>
                                                <TagCloseButton onClick={() => handleQuestionnaireChanges("accessTeam", [...initialValues.questionnaire.accessTeam.slice(0, index), ...initialValues.questionnaire.accessTeam.slice(index + 1)])} />
                                            </Tag>)}
                                        </Flex>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Équipe en écriture</FormLabel>
                                        <InputGroup mb={4}>
                                            <Input
                                                type={'text'}
                                                value={newModifyTeam}
                                                onChange={(e) => setNewModifyTeam(e.target.value)}
                                                onKeyDown={(e) => onKeyPressEnter(e, handleNewModifyTeam)}

                                            />
                                            <InputRightElement width='4.5rem' pr={1}>
                                                <Button size='xs' onClick={handleNewModifyTeam}>
                                                    Ajouter
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Flex align="center" gap={1}>
                                            {initialValues.questionnaire.modifyTeam.map((team, index) => <Tag
                                                key={index}
                                                variant='solid'
                                                colorScheme='teal'
                                            >
                                                <TagLabel>{team}</TagLabel>
                                                <TagCloseButton onClick={() => handleQuestionnaireChanges("modifyTeam", [...initialValues.questionnaire.modifyTeam.slice(0, index), ...initialValues.questionnaire.modifyTeam.slice(index + 1)])} />
                                            </Tag>)}
                                        </Flex>
                                    </FormControl>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
                <Box py={4}>
                    <Heading>Questions</Heading>
                    <Flex w="full" gap={4} mt={4} direction="column">
                        {initialValues.questionnaire.questions.map((question, index) => (
                            <Box boxShadow={"base"} p={4} key={index}>
                                <FormControl mb={4} >
                                    <FormLabel>Titre</FormLabel>
                                    <Flex alignItems="center" gap={4} direction={{ base: "column", md: "row" }}>
                                        <Input type='text' value={question.description} onChange={(e) => handleQuestionChanges(index, "description", e.target.value)} />
                                        <Button colorScheme='red' variant="ghost" aria-label={"Delete question " + index} leftIcon={<FaTrash />} onClick={() => {
                                            handleQuestionnaireChanges("questions", [...initialValues.questionnaire.questions.slice(0, index), ...initialValues.questionnaire.questions.slice(index + 1)])
                                        }}>Supprimer la question</Button>
                                    </Flex>
                                </FormControl>
                                <FormControl mb={4}>
                                    <FormLabel>Image</FormLabel>
                                    {question.image && !question.tmp && <ImageFromS3 imageKey={question.image} />}
                                    {question.image && question.tmp && <Image src={question.tmp} mb={4} />}
                                    <Flex alignItems="center" gap={4}>
                                        <Input type='file' accept="image/*" onChange={(e) => handleImageChange(index, e.target.files[0])} height="100%" p={4} />
                                    </Flex>
                                </FormControl>

                                <FormControl mb={4}>
                                    <FormLabel>Type de question</FormLabel>
                                    <Flex alignItems="center" gap={4}>
                                        <RadioGroup value={question.type} onChange={(newType) => {
                                            // If type is unique make sure only 1 answer is selected
                                            handleQuestionChanges(index, "type", newType)

                                            if (newType === "unique") {
                                                let sortedResponse = initialValues.questionsAnswers[question._id]
                                                if (!sortedResponse) {
                                                    return;
                                                }
                                                sortedResponse.sort((a, b) => a - b)
                                                setInitialValues({ ...initialValues, questionsAnswers: { ...initialValues.questionsAnswers, [question._id]: [sortedResponse[0]] } })
                                            }
                                        }}>
                                            <Stack spacing={4} direction='row'>
                                                <Radio value='multiple'>
                                                    Choix multiple
                                                </Radio>
                                                <Radio value='unique'>Choix unique</Radio>
                                                <Radio value='short'>Réponse courte</Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </Flex>
                                    {/* <Flex alignItems="center" gap={4}>
                                        <Input type='text' value={question.description} onChange={(e) => handleQuestionChanges(index, "description", e.target.value)} />
                                        <Button colorScheme='red' variant="ghost" aria-label={"Delete question " + index} leftIcon={<FaTrash />} onClick={() => {
                                            handleQuestionnaireChanges("questions", [...initialValues.questionnaire.questions.slice(0, index), ...initialValues.questionnaire.questions.slice(index + 1)])
                                        }}>Supprimer la question</Button>
                                    </Flex> */}
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Réponses</FormLabel>
                                    {showRadio.includes(question.type) && question.responses.map((response, response_index) => (
                                        <Flex mb={2} gap={4} key={response_index}>
                                            <Checkbox isChecked={initialValues.questionsAnswers[question._id] && initialValues.questionsAnswers[question._id].includes(response_index)} onChange={(e) => handleQuestionAnswersChange(question._id, response_index, e.target.checked)} />
                                            <Input type='text' value={response} onChange={(e) => handleQuestionResponseChanges(index, response_index, e.target.value)} />
                                            <IconButton variant="ghost" colorScheme='red' aria-label={"Delete response " + response_index} icon={<FaTrash />} onClick={() => {
                                                handleQuestionChanges(index, "responses", [...question.responses.slice(0, response_index), ...question.responses.slice(response_index + 1)], response_index)
                                            }} />
                                        </Flex>
                                    ))}
                                    <Flex mb={1} gap={4}>
                                        {showRadio.includes(question.type) ?
                                            <>
                                                <Input ml={8} type='text' value={newAnswer[question._id] || ""} onChange={(e) => {
                                                    if (!newAnswer[question._id]) {
                                                        newAnswer[question._id] = ""
                                                    }
                                                    setNewAnswer({ ...newAnswer, [question._id]: e.target.value })
                                                }} />
                                                <IconButton aria-label={"Add response " + index} icon={<FaPlus />} onClick={() => {
                                                    setNewAnswer({ ...newAnswer, [question._id]: "" })

                                                    handleQuestionChanges(index, "responses", [...question.responses, newAnswer[question._id]])
                                                }} />
                                            </>
                                            :
                                            <Textarea defaultValue={question.responses[0]} onChange={(e) => {
                                                if (!newAnswer[question._id]) {
                                                    newAnswer[question._id] = ""
                                                }
                                                handleQuestionChanges(index, "responses", [e.target.value])
                                                handleQuestionAnswersChange(question._id, 0, true)
                                                setNewAnswer({ [question._id]: e.target.value })
                                            }}>

                                            </Textarea>
                                        }
                                    </Flex>
                                </FormControl>
                            </Box>
                        ))}
                        <Flex mb={1} gap={4} direction={{ base: "column", md: "row" }}>
                            <Input type='text' value={newQuestion} placeholder='Titre de la nouvelle question' onChange={(e) => {
                                setNewQuestion(e.target.value)
                            }} />

                            <Button aria-label={"Add question "} leftIcon={<FaPlus />} onClick={() => {
                                handleQuestionnaireChanges("responses", initialValues.questionnaire.questions.push({ _id: generateUuid(), description: newQuestion, type: "unique", responses: [], index: initialValues.questionnaire.questions.length }))
                                setNewQuestion("")
                            }}>
                                Ajouter une question
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Container >
        </>
    )
}

export default QuestionnaireUpdateComponent