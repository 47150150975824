import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Authentication/ProtectedRoute';
import { API_URL } from "../keys";
import { Badge, Box, Button, Card, Container, Flex, Grid, GridItem, Heading, Tag, Text } from '@chakra-ui/react';
import { displayOfferEndMessage, formatDateForPrint } from '../Utils/FormatDate';
import { FaArrowRight, FaBusinessTime, FaCoins, FaFileContract, FaFileSignature, FaMapPin, FaMoneyBill } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const JobSearchComponent = () => {
    const [jobs, setJobs] = useState(null)
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    useEffect(() => {
        loadUserJobOffers()
    }, [])

    const loadUserJobOffers = async () => {
        try {
            const response = await fetch(API_URL + '/joboffers/team/65fb5281271b8aa0a60306f3', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setJobs(data)
        } catch (error) {
        }
    }

    return (
        <> <Container maxW="full" p={4}>
            <Flex alignItems='center' gap={4}>
                <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                <Heading mb={4} color={"gray.700"}>Les offres d'emploi</Heading>
                <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
            </Flex>
            <Box w='100%' my={4}>
                <Grid templateColumns='repeat(4, 1fr)' gap={6}>
                    {jobs && jobs.map((job, index) => <GridItem key={index}>
                        <Card px={4} py={4}>
                            <Flex gap={2}>
                                <Badge>
                                    <Flex alignItems={"center"} gap={2}>
                                        <FaMoneyBill /> {job.salary && job.salary[0]}€ - {job.salary && job.salary[1]}€
                                    </Flex>
                                </Badge>
                                <Badge>
                                    <Flex alignItems={"center"} gap={2}>
                                        <FaFileSignature /> {job.contract_type}
                                    </Flex>
                                </Badge>
                                <Badge>
                                    <Flex alignItems={"center"} gap={2}>
                                        <FaBusinessTime /> {job.contract_time}
                                    </Flex>
                                </Badge>
                                <Badge marginLeft="auto">{displayOfferEndMessage(job.expirationDate)}</Badge>
                            </Flex>
                            <Flex gap={2} mt={2}>
                                <Badge>
                                    <Flex alignItems={"center"} gap={2}>
                                        <FaMapPin /> {job.location}
                                    </Flex>
                                </Badge>
                            </Flex>
                            <Heading mt={6} mb={2}>{job.name}</Heading>
                            <Text>{job.description}</Text>
                            <Flex gap={2} mt={4} alignItems={"center"}>
                                {job.tags.map((tag, index) => <Badge key={index}>
                                    {tag}
                                </Badge>)}
                                <Button marginLeft="auto" size="sm" colorScheme='teal' rightIcon={<FaArrowRight />} onClick={() => navigate("/job_search/" + job._id)}>Visualiser l'offre d'emploi</Button>
                            </Flex>
                        </Card>
                    </GridItem>)}
                </Grid>
            </Box>
        </Container>
        </>
    )
}

export default JobSearchComponent