import React, { useEffect, useState, useContext } from 'react'
import { SimpleGrid, FormControl, FormLabel, Box, Button, Card, CardBody, CardHeader, Container, Flex, Grid, Heading, Input, Stack, StackDivider, Tag, Text, Tooltip, useToast } from '@chakra-ui/react'
import CopyToClipboard from '../Utils/CopyToClipboard'
import { MdOutlineLogout } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { API_URL } from "../keys";
import ProfileUpdateTeam from '../Components/ProfileUpdateTeam'
import { FaCloudArrowDown } from 'react-icons/fa6'
import { LuMoreVertical } from "react-icons/lu";
import { FaEdit } from "react-icons/fa";
import { UserContext } from '../Authentication/ProtectedRoute';
import { updateUserPassword, logout } from "../API/Users";

const ProfileComponent = () => {
    const toast = useToast()
    const navigate = useNavigate();
    const [userInformations, setUserInformations] = useState({ email: null, firstname: null, lastname: null, roles: null, teams: [] })
    const [isVisible, setIsVisible] = useState(false)
    const [team, setTeam] = useState(null)
    const [isUpdateProfile, setIsUpdateProfile] = useState(false)
    const [isUpdatePassword, setIsUpdatePassword] = useState(false)
    const [errors, setErrors] = useState({})
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const { user, setUser } = useContext(UserContext);


    const loadUserProfile = () => {
        if (user) { // Vérifie que les données utilisateur sont présentes
            setUserInformations(user);
        }
    }

    const saveUserProfileUpdate = async () => {

        try {
            let { lastname, firstname, email, _id } = userInformations;
            const response = await fetch(
                API_URL + '/users/' + _id,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ lastname, firstname, email })
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else {
                toast({
                    description: "Votre profil a bien été modifié !",
                    status: 'info',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                })
            }

            const data = await response.json();
            setUser(data);
            setUserInformations(data);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const switchEditionProfileMode = async () => {
        if (!isUpdateProfile) setIsUpdateProfile(true)
        else {
            await saveUserProfileUpdate();
            setIsUpdateProfile(false)


            loadUserProfile();
        }
    }

    const updatePassword = async (e) => {
        e.preventDefault();

        try {
            if (newPassword !== confirmNewPassword) {
                toast({
                    description: "Les mots de passe ne correspondent pas",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                })
                return;
            }

            const response = await updateUserPassword(oldPassword, newPassword, confirmNewPassword);

            if (!response.msg) {
                throw new Error('Failed to fetch data');
            } else {
                toast({
                    description: response.msg,
                    status: 'info',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                })
            }
            setConfirmNewPassword('')
            setNewPassword('')
            setOldPassword('')
            setIsUpdatePassword(false)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const copyIdentifiant = async () => {
        let status = await CopyToClipboard(userInformations._id)
        if (status) {
            toast({
                title: 'Presse papier.',
                description: `Votre identifiant a bien été copié.`,
                status: 'info',
                duration: 9000,
                isClosable: true,
                position: 'top'
            })
        } else {
            toast({
                title: 'Presse papier.',
                description: `Votre identifiant n'a pas pu être copié.`,
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top'
            })
        }
    }

    const handleLogout = async () => {
        logout()
        navigate("/login")
    }

    useEffect(() => {
        loadUserProfile();
    }, [user])

    const getTagByRole = (role) => {
        if (role === "member") return "Membre"
        else if (role === "administrator") return "Administrateur"
        else if (role === "candidate") return "Candidat"
    }

    const closeDrawer = () => {
        setIsVisible(false)
        setTeam(null)
    }

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={4} color={"gray.700"}>Mon profil</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <Flex direction="column" gap={4}>
                    <Flex gap={4} direction={["column", "column", "row"]}>
                        <Card flex={1}>
                            <CardHeader display={"flex"} justifyContent={"space-between"}>
                                <Heading size='md'>Informations personnelles</Heading>
                                <Flex gap={4}>
                                    {isUpdateProfile ? <Button size={"sm"} variant={"ghost"} colorScheme='teal' onClick={() => {
                                        loadUserProfile();
                                        setIsUpdateProfile(false);
                                    }}>
                                        annuler les modifications
                                    </Button>
                                        : null}
                                    <Button size={"sm"} colorScheme={isUpdateProfile ? 'teal' : 'gray'} onClick={() => {
                                        switchEditionProfileMode()
                                    }} rightIcon={<LuMoreVertical />}>
                                        {isUpdateProfile ? "Sauvegarder les modifications" : "Modifier mes informations"}
                                    </Button>
                                </Flex>
                            </CardHeader>
                            <CardBody>
                                <Stack divider={<StackDivider />} spacing='4'>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Votre identifiant
                                        </Heading>
                                        <Text pt='2' fontSize='sm'>
                                            <Tooltip label='Cliquer pour copier' placement="right">
                                                <Tag
                                                    variant='solid'
                                                    colorScheme='teal'
                                                    cursor="pointer"
                                                    onClick={copyIdentifiant}
                                                >
                                                    {userInformations._id}
                                                </Tag>
                                            </Tooltip>
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Nom
                                        </Heading>
                                        {isUpdateProfile ? <Input mt={4} value={userInformations.lastname} onChange={e => setUserInformations({ ...userInformations, lastname: e.target.value })} /> : <Text pt='2' fontSize='sm'>
                                            {userInformations.lastname}
                                        </Text>}
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Prénom
                                        </Heading>
                                        {isUpdateProfile ? <Input mt={4} value={userInformations.firstname} onChange={e => setUserInformations({ ...userInformations, firstname: e.target.value })} /> : <Text pt='2' fontSize='sm'>
                                            {userInformations.firstname}
                                        </Text>}
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Email
                                        </Heading>
                                        {isUpdateProfile ? <Input mt={4} value={userInformations.email} onChange={e => setUserInformations({ ...userInformations, email: e.target.value })} /> : <Text pt='2' fontSize='sm'>
                                            {userInformations.email}
                                        </Text>}
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Rôle
                                        </Heading>
                                        <Text pt='2' fontSize='sm'>
                                            {getTagByRole(userInformations.roles)}
                                        </Text>
                                    </Box>
                                </Stack>
                            </CardBody>
                        </Card>
                        <Card flex={1}>
                            <CardHeader>
                                <Heading size='md'>Vos actions</Heading>
                            </CardHeader>
                            <CardBody display={"flex"} flexDirection={"column"} gap={4}>
                                {!isUpdatePassword ?
                                    <Button colorScheme='teal' leftIcon={<FaEdit />} onClick={() => setIsUpdatePassword(true)}>Modifier mon mot de passe</Button>
                                    :
                                    <form onSubmit={updatePassword}>
                                        <Stack divider={<StackDivider />} spacing='4'>
                                            <Box>
                                                <FormControl isInvalid={errors && errors.password}>
                                                    <FormLabel>Mot de passe actuel</FormLabel>
                                                    <Input type='password' value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                                                    {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors && errors.password}>
                                                    <FormLabel>Nouveau mot de passe</FormLabel>
                                                    <Input type='password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                                    {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors && errors.confirmPassword}>
                                                    <FormLabel>Confirmation du nouveau mot de passe</FormLabel>
                                                    <Input type='password' value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
                                                    {errors && errors.confirmPassword && <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>}
                                                </FormControl>
                                            </Box>
                                            <SimpleGrid columns={2} spacing={4}>
                                                <Button variant='outline' onClick={() => setIsUpdatePassword(false)}>Annuler</Button>
                                                <Button type='submit' colorScheme='teal'>Confirmer</Button>
                                            </SimpleGrid>
                                        </Stack>
                                    </form>
                                }
                                <Button colorScheme='red' leftIcon={<MdOutlineLogout />} onClick={handleLogout}>Se déconnecter</Button>
                                <Button colorScheme='red' variant={"outline"} leftIcon={<MdOutlineLogout />} onClick={() => {
                                    // TODO DELETE ACCOUNT
                                }}>Supprimer mon compte</Button>
                                <Button variant={"outline"} leftIcon={<FaCloudArrowDown />} onClick={() => {
                                    // TODO DELETE ACCOUNT
                                }}>Récupérer les informations liés à mon compte</Button>
                            </CardBody>
                        </Card>
                    </Flex>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={4}>
                        {userInformations && userInformations.teams && userInformations.roles !== "candidate" && userInformations.teams.map((teamInfo, index) => (
                            <Card flex={1} key={index}>
                                <CardHeader display={"flex"} justifyContent={"space-between"}>
                                    <Heading size='md' textTransform={"uppercase"}>Équipe {index + 1}</Heading>
                                    <Button size={"sm"} colorScheme='teal' onClick={() => {
                                        setIsVisible(true)
                                        setTeam({ id: teamInfo._id, role: teamInfo.role })
                                    }} rightIcon={<LuMoreVertical />}>
                                        Voir le détail de l'équipe
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <Stack divider={<StackDivider />} spacing='4'>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Identifiant d'équipe
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                <Tooltip label='Cliquer pour copier' placement="right">
                                                    <Tag
                                                        variant='solid'
                                                        cursor="pointer"
                                                        onClick={async () => {
                                                            let status = await CopyToClipboard(teamInfo._id)
                                                            if (status) {
                                                                toast({
                                                                    title: 'Presse papier.',
                                                                    description: `L'identifiant de votre équipe ${teamInfo.name} a bien été copié.`,
                                                                    status: 'info',
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                    position: 'top'
                                                                })
                                                            } else {
                                                                toast({
                                                                    title: 'Presse papier.',
                                                                    description: `L'identifiant de votre équipe ${teamInfo.name} n'a pas pu être copié.`,
                                                                    status: 'error',
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                    position: 'top'
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {teamInfo._id}
                                                    </Tag>
                                                </Tooltip>
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Nom de l'équipe
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                {teamInfo.name}
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Description
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                {teamInfo.description}
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Votre rôle dans l'équipe
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                <Tag bg={teamInfo.role === "administrator" ? 'gray.600' : 'blue.400'} color="white">{getTagByRole(teamInfo.role)}</Tag>
                                            </Text>
                                        </Box>
                                    </Stack>
                                </CardBody>
                            </Card>
                        ))}
                    </Grid>
                </Flex>
            </Container >
            <ProfileUpdateTeam isVisible={isVisible} onClose={closeDrawer} team={team} reload={loadUserProfile} />
        </>
    )
}

export default ProfileComponent