import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from './Authentication/ProtectedRoute';
import Homepage from './Homepage';
import reportWebVitals from './reportWebVitals';
import CandidatComponent from './Routes/CandidatComponent';
import CandidatHomepage from './Routes/CandidatHomepage';
import CandidatQuestionnaireExam from './Routes/CandidatQuestionnaireExam';
import CandidatRegistrationComponent from './Routes/CandidatRegistrationComponent';
import CandidatsComponent from './Routes/CandidatsComponent';
import GoogleAuth from './Routes/GoogleAuth';
import JobOfferComponent from './Routes/JobOfferComponent';
import JobOffersComponent from './Routes/JobOffersComponent';
import JobOfferUpdateComponent from './Routes/JobOfferUpdateComponent';
import LoginComponent from './Routes/LoginComponent';
import MentionLegalesComponent from './Routes/MentionsLegalesComponent';
import NotFoundComponent from './Routes/NotFoundComponent';
import ProfileComponent from './Routes/ProfileComponent';
import QuestionnaireComponent from './Routes/QuestionnaireComponent';
import QuestionnaireResultsComponent from './Routes/QuestionnaireResultsComponent';
import QuestionnairesComponent from './Routes/QuestionnairesComponent';
import QuestionnaireUpdateComponent from './Routes/QuestionnaireUpdateComponent';
import JobSearchComponent from './Routes/JobSearchComponent';
import JobSearchInfosComponent from './Routes/JobSearchInfosComponent';
const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginComponent />
  },
  {
    path: "/register_candidate/:team_id",
    element: <CandidatRegistrationComponent />
  },
  {
    path: "/paul-emploi",
    element: <ProtectedRoute component={JobOffersComponent} />
  },
  {
    path: "/paul-emploi/:id",
    element: <ProtectedRoute component={JobOfferComponent} />
  },
  {
    path: "/paul-emploi/update/:id",
    element: <ProtectedRoute component={JobOfferUpdateComponent} />
  },
  {
    path: "/exam",
    element: <ProtectedRoute component={CandidatHomepage} />
  },
  {
    path: "/job_search",
    element: <ProtectedRoute component={JobSearchComponent} />
  },
  {
    path: "/job_search/:job_id",
    element: <ProtectedRoute component={JobSearchInfosComponent} />
  },
  {
    path: "/exam/:questionnaire_id",
    element: <ProtectedRoute component={CandidatQuestionnaireExam} />
  },
  {
    path: "/exam/responses/:questionnaire_id",
    element: <ProtectedRoute component={QuestionnaireResultsComponent} />
  },
  {
    path: "/questionnaires",
    element: <ProtectedRoute component={QuestionnairesComponent} />
  },
  {
    path: "/questionnaire/:id",
    element: <ProtectedRoute component={QuestionnaireComponent} />
  },
  {
    path: "/questionnaire/update/:id",
    element: <ProtectedRoute component={QuestionnaireUpdateComponent} />
  },
  {
    path: "/candidats",
    element: <ProtectedRoute component={CandidatsComponent} />
  },
  {
    path: "/candidat/:candidat_id",
    element: <ProtectedRoute component={CandidatComponent} />
  },
  {
    path: "/profil",
    element: <ProtectedRoute component={ProfileComponent} />
  },
  {
    path: "/",
    element: <ProtectedRoute component={Homepage} />
  },
  {
    path: "/mentions-legales",
    element: <MentionLegalesComponent />
  },
  {
    path: "/auth/google",
    element: <GoogleAuth />
  },
  {
    path: "*",
    element: <NotFoundComponent />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ChakraProvider>
    <RouterProvider router={router} />
  </ChakraProvider>
  // </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();