import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Flex, Heading, Input } from '@chakra-ui/react';
import { FaPlusSquare } from 'react-icons/fa';
import { UserContext } from '../Authentication/ProtectedRoute';
import JobOffersTable from '../Components/JobOffersTable';
import { API_URL } from "../keys";

const JobOfferUpdateComponent = () => {
    const { user } = useContext(UserContext)
    const [jobOffers, setJobOffers] = useState([])
    const [searchBar, setSearchBar] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        loadUserJobOffers()
    }, [])

    const loadUserJobOffers = async () => {
        try {
            const response = await fetch(API_URL + '/joboffers', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setJobOffers(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return (
        <>
            <Container maxW="full" p={4}>
                <Flex alignItems='center' gap={4} mb={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>

                    <Heading mb={4} color={"gray.700"}>Les Offres d'emplois</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                    <Button leftIcon={<FaPlusSquare />} onClick={() => navigate("/paul-emploi/update/new")} size="sm" colorScheme="teal" variant="ghost">
                        Créer une offre d'emploi
                    </Button>
                </Flex>
                <Box my={4}>
                    <Input variant='flushed' placeholder="Rechercher une offre d'emploi" value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                </Box>
                <Box w='100%' boxShadow={"base"} borderRadius={"md"}>
                    <JobOffersTable jobOffers={jobOffers.filter(row => row.name.toLowerCase().includes(searchBar.toLowerCase()) || row.content.toLowerCase().includes(searchBar.toLowerCase()))} reload={loadUserJobOffers} />
                </Box>
            </Container>
        </>
    )
}

export default JobOfferUpdateComponent