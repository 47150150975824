import React, { useContext, useEffect } from 'react'
import { Flex, Box, Heading, Spacer, Button, IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { FaSignInAlt, FaUser } from "react-icons/fa";
import { UserContext } from "../Authentication/ProtectedRoute";
import { useNavigate, useLocation } from "react-router-dom";
import HorizontalLogo from "../Resources/Logos/HoritzontalLogoTransparency.png";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
    const { user } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="1rem"
            color="gray.800"
            boxShadow={"base"}
        >
            <Box cursor="pointer" onClick={() => navigate("/")} marginRight={6}>
                <img width={150} src={HorizontalLogo} alt="Aptitude logo horizontal" />
            </Box>

            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<GiHamburgerMenu />}
                    variant='outline'
                    display={{ base: 'flex', md: 'none' }}
                />
                <MenuList display={{ md: 'none' }}>
                    <MenuItem onClick={() => navigate("/")}>
                        Accueil
                    </MenuItem>
                    {user && user.roles !== "candidate" && user.firstname ?
                        <>
                            <MenuItem onClick={() => navigate("/questionnaires")}>
                                Questionnaires
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/candidats")}>
                                Candidats
                            </MenuItem>
                        </>
                        : null
                    }
                    {user && user.roles === "candidate" && user.firstname ?
                        <>
                            <MenuItem onClick={() => navigate("/exam")}>
                                Tableau de bord
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/job_search")}>
                                Offre d'emplois
                            </MenuItem>
                        </>
                        : null
                    }
                    {user.firstname ? <MenuItem onClick={() => navigate("/profil")}>
                        Mon profil
                    </MenuItem> : null}
                </MenuList>
            </Menu>

            <Box
                display={{ base: 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                flexGrow={1}
            >
                {/* <Button variant="ghost" mr={2}>
                    Offres d'emplois
                </Button> */}
                {user && user.roles !== "candidate" && user.firstname ?
                    <>
                        <Button variant={location.pathname === "/questionnaires" ? "solid" : "ghost"} colorScheme={location.pathname === "/questionnaires" ? "teal" : "gray"} mr={2} onClick={() => navigate("/questionnaires")}>
                            Questionnaires
                        </Button>
                        <Button mr={2} variant={location.pathname === "/candidats" ? "solid" : "ghost"} colorScheme={location.pathname === "/candidats" ? "teal" : "gray"} onClick={() => navigate("/candidats")}>
                            Candidats
                        </Button>
                        <Button mr={2} variant={location.pathname === "/paul-emploi" ? "solid" : "ghost"} colorScheme={location.pathname === "/paul-emploi" ? "teal" : "gray"} onClick={() => navigate("/paul-emploi")}>
                            Offres d'emploi
                        </Button>
                    </>
                    : null
                }
                {user && user.roles === "candidate" && user.firstname ?
                    <>
                        <Button variant={location.pathname === "/exam" ? "solid" : "ghost"} colorScheme={location.pathname === "/exam" ? "teal" : "gray"} mr={2} onClick={() => navigate("/exam")}>
                            Tableau de bord
                        </Button>
                        <Button mr={2} variant={location.pathname === "/job_search" ? "solid" : "ghost"} colorScheme={location.pathname === "/job_search" ? "teal" : "gray"} onClick={() => navigate("/job_search")}>
                            Offres d'emploi
                        </Button>
                    </>
                    : null
                }
            </Box>

            <Spacer display={{ base: 'none', md: 'flex' }} />
            {user.firstname ? <Box
                display={{ base: 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                gap={2}
            >
                <Button
                    leftIcon={<FaUser />}
                    colorScheme="teal"
                    // size="lg"
                    onClick={() => navigate("/profil")}
                >
                    {user.firstname} {user.lastname}
                </Button>
            </Box> : <Box
                display={{ base: 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                gap={2}
            >
                <Button
                    leftIcon={<FaSignInAlt />}
                    colorScheme="teal"
                    // size="lg"
                    onClick={() => navigate("/login")}
                >
                    Se connecter
                </Button>
            </Box>}

        </Flex>
    );
}

export default Navbar