import React, { useContext, useEffect, useRef, useState } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Box,
    Image,
    Flex,
    Text,
    Divider,
    useToast
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom"
import { UserContext } from '../Authentication/ProtectedRoute'
import { FaEye, FaTrash } from 'react-icons/fa'
import { API_URL } from "../keys";
import EmptyCandidates from "../Resources/Images/empty_candidates.svg";
import { FaFileContract, FaPlus } from 'react-icons/fa6'

const CandidatsTable = ({ candidats, reload }) => {
    const { user } = useContext(UserContext)
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAddJobOffer, onOpen: onOpenAddJobOffer, onClose: onCloseAddJobOffer } = useDisclosure();
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)
    const [candidateToAddJobOffer, setCandidateToAddJobOffer] = useState(null)
    const [jobOffers, setJobOffers] = useState(null)
    const toast = useToast()


    const getJobOffersByTeamId = async (teamId) => {
        try {
            const response = await fetch(`${API_URL}/joboffers/team/${teamId}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            return data
        } catch (error) {
            console.error('Error fetching data:', error.message);
            return []
        }
    }

    useEffect(() => {
        const loadJobOffers = async () => {
            const data = (await Promise.all(user.teams.map(team => getJobOffersByTeamId(team._id)))).flat()
            setJobOffers(data)
        }
        loadJobOffers()
    }, [user.teams])

    const getActionByRole = (candidatTeam, candidatId) => {
        const workingTeam = user && user.teams && user.teams.find(team => team._id === candidatTeam)
        if (workingTeam && workingTeam.role === "administrator") {
            return (<>
                <IconButton
                    aria-label='Read'
                    icon={<FaEye />}
                    mr={1}
                    onClick={() => navigate(`/candidat/${candidatId}`)}
                />
                <IconButton
                    mr={1}
                    onClick={() => {
                        setCandidateToAddJobOffer(candidatId)
                        onOpenAddJobOffer()
                    }}
                    aria-label='Add job offer'
                    icon={<FaFileContract />}
                />
                <IconButton
                    onClick={() => {
                        setToDeletion({ candidatId: candidatId, teamId: candidatTeam })
                        onOpen()
                    }}
                    colorScheme='red'
                    aria-label='Delete'
                    icon={<FaTrash />}
                />
            </>)

        } else if (workingTeam && workingTeam.role === "member") {
            return (<>
                <IconButton
                    mr={1}
                    onClick={() => {
                        setCandidateToAddJobOffer(candidatId)
                        onOpenAddJobOffer()
                    }}
                    aria-label='Add job offer'
                    icon={<FaFileContract />}
                />
                <IconButton
                    aria-label='Read'
                    icon={<FaEye />}
                    mr={1}
                    onClick={() => navigate(`/candidat/${candidatId}`)}
                />
            </>)
        } else {
            return (<></>)
        }

    }

    const handleDeletion = async () => {
        try {
            const response = await fetch(`${API_URL}/teams/${toDeletion.teamId}/candidate/${toDeletion.candidatId}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                reload()

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const handleSubmitAddOrDeleteJobOffer = async (job_offer_id) => {
        try {
            const response = await fetch(`${API_URL}/users/candidat/${candidateToAddJobOffer}/addOrDeleteJobOffer/${job_offer_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                toast({
                    title: 'Erreur lors de la modification de l\'offre d\'emploi',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })

                throw new Error('Failed to fetch data');
            } else {
                reload()
                toast({
                    title: 'Offre d\'emploi modifiée avec succès',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }


    return candidats.length === 0 ? <Flex justifyContent={"center"}>
        <Box p={4}>
            <Image src={EmptyCandidates} alt="No candidates" maxHeight={500} />
            <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Vos candidats apparaîtrons ici !</Text>
        </Box>
    </Flex> : (
        <>
            <TableContainer width={"100%"}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Prénom</Th>
                            <Th>Email</Th>
                            <Th>Équipe</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {candidats.map((candidat, index) => (
                            <Tr key={index}>
                                <Td>{candidat.candidate_lastname}</Td>
                                <Td>{candidat.candidate_firstname}</Td>
                                <Td>{candidat.candidate_email}</Td>
                                <Td>{candidat.team_name}</Td>
                                <Td>{getActionByRole(candidat.team_id, candidat.candidate_id)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog
                size={"xl"}
                isOpen={isOpenAddJobOffer}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setCandidateToAddJobOffer(null)
                    onCloseAddJobOffer()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Ajouter une offre d'emploi
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            {jobOffers.map((jobOffer, index) => {
                                const isJobOfferAlreadyAdded = candidats.find(candidat => {
                                    return candidat.candidate_id === candidateToAddJobOffer && candidat.candidate_jobs_offer && candidat.candidate_jobs_offer.includes(jobOffer._id)
                                });

                                return <React.Fragment key={index}>
                                    <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                                        <Text fontSize='sm'>{jobOffer.name}</Text>
                                        {!isJobOfferAlreadyAdded && <Button rightIcon={<FaPlus />} size={"sm"} variant={"ghost"} color={"teal"} onClick={async () => {
                                            await handleSubmitAddOrDeleteJobOffer(jobOffer._id);
                                        }}>Lié à cette offre</Button>}
                                        {isJobOfferAlreadyAdded && <Button rightIcon={<FaTrash />} size={"sm"} variant={"ghost"} color={"red"} onClick={async () => {
                                            await handleSubmitAddOrDeleteJobOffer(jobOffer._id, true);
                                        }}>Supprimer de cette offre</Button>}
                                    </Box>
                                    <Divider />
                                </React.Fragment>
                            })}
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={() => {
                                    setCandidateToAddJobOffer(null)
                                    onCloseAddJobOffer()
                                }}>
                                    Fermer
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le candidat <b>{toDeletion && toDeletion.candidatId}</b> de l'équipe <b>{toDeletion && toDeletion.teamId}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default CandidatsTable