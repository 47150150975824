import React, { useRef, useState } from 'react'
import { IconButton, Box, Heading, Flex, Text, Tag, TagLeftIcon, TagLabel, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, useDisclosure } from '@chakra-ui/react';
import { FaTrash, FaPenToSquare, FaEye } from "react-icons/fa6";
import { convertTypeToLabel } from '../Utils/QuestionnairesUtils';
import { useNavigate } from "react-router-dom"
import { removeQuestionnaire } from '../API/Questionnaires';

const QuestionnaireHeader = ({ _id, name, description, tags, type, accessTeam, modifyTeam, teamOwner, permissions }) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)

    const handleDeletion = async () => {
        try {
            const response = await removeQuestionnaire(toDeletion);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                navigate('/questionnaires')

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }


    return (
        <Box w='100%' boxShadow={"base"} p={4} pt={2} color="gray.700" bg={"#F7FAFC"}>
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='4xl'>{name}</Text>
                <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag>
                {permissions === 'own' || permissions === 'write' ? <Flex ml="auto">
                    <IconButton
                        size='sm'
                        aria-label='Update'
                        colorScheme='teal'
                        icon={<FaPenToSquare />}
                        onClick={() => navigate(`/questionnaire/update/${_id}`)}
                        mr={1}
                    />
                    <IconButton
                        size='sm'
                        colorScheme='red'
                        aria-label='Delete'
                        icon={<FaTrash />}
                        onClick={() => {
                            setToDeletion(_id)
                            onOpen()
                        }}
                    />
                </Flex> : null}
            </Flex>
            <Flex alignItems="baseline" gap={4}>
                <Text fontSize='md'>{description}</Text>
            </Flex>
            <Flex alignItems="baseline" gap={1} mt={4}>
                {tags && tags.map((tag, index) => (
                    <Tag fontSize='xs' key={index} variant='solid' colorScheme="teal">{tag}</Tag>
                ))}
                <Flex ml="auto" gap={1}>
                    <Tag fontSize='xs' variant='solid' colorScheme="teal">{convertTypeToLabel(type)}</Tag>
                    <Tag size={'md'} variant='solid' colorScheme="teal">
                        <TagLeftIcon boxSize='12px' as={FaEye} />
                        <TagLabel>{accessTeam && accessTeam.length}</TagLabel>
                    </Tag>
                    <Tag size={'md'} variant='solid' colorScheme="teal">
                        <TagLeftIcon boxSize='12px' as={FaPenToSquare} />
                        <TagLabel>{modifyTeam && modifyTeam.length}</TagLabel>
                    </Tag>
                </Flex>
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le questionnaire <b>{toDeletion}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
}

export default QuestionnaireHeader