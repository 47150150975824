export const convertTypeToLabel = (type) => {
    if (type === "public") {
        return "Publique"
    } else if (type === "private") {
        return "Privée"
    } else {
        return "Aucune"
    }
}

export const GetLabelFromType = (type) => {
    if (type === "unique") {
        return "Choix unique"
    } else if (type === "multiple") {
        return "Choix multiple"
    } else if (type === "short") {
        return "Reponse courte"
    } else {
        return "Aucun"
    }
}