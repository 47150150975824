import { API_URL } from "../keys";

export const login = async (email, password) => {
    const response = await fetch(API_URL + '/users/login', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    });

    return await response.json();
}

export const getUserInfos = async () => {
    const response = await fetch(
        API_URL + '/users/infos',
        {
            method: 'GET',
            credentials: 'include'
        }
    )
    return await response.json();
}

export const logout = async () => {
    await fetch(API_URL + '/users/logout', {
        credentials: 'include'
    })
}

export const updateUserPassword = async (oldPassword, newPassword, confirmNewPassword) => {
    const response = await fetch(
        API_URL + '/users/password',
        {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                oldPassword,
                newPassword,
                confirmNewPassword
            })
        }
    );

    return await response.json();
}