import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    IconButton,
    Image,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaEye, FaPenToSquare, FaTrash } from "react-icons/fa6";

import { useNavigate } from 'react-router-dom';
import { removeJobOffer } from '../API/JobOffers';
import EmptyQuestionnaires from "../Resources/Images/empty_questionnaires.svg";
import { formatDateForPrint } from '../Utils/FormatDate';

const JobOffersTable = ({ jobOffers, reload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
    }, [jobOffers])

    const handleDeletion = async () => {
        try {
            if (!await removeJobOffer(toDeletion)) {
                throw new Error('Failed to fetch data');
            } else {
                toast({
                    title: 'Offre d\'emploi supprimée',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                reload()
            }

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return jobOffers.length === 0 ?
        <Flex justifyContent={"center"}>
            <Box p={4}>
                <Image src={EmptyQuestionnaires} alt="No questionnaires" maxHeight={500} />
                <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Vos offres d'emploi apparaîtrons ici !</Text>
            </Box>
        </Flex>
        :
        <>
            <TableContainer width={"100%"} >
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Team</Th>
                            <Th>Date de publication</Th>
                            <Th>Date de fin de l'offre</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {jobOffers.map((jobOffer, index) => (
                            <Tr key={index}>
                                <Td>{jobOffer.name}</Td>
                                <Td>{jobOffer.team.name}</Td>
                                <Td>{formatDateForPrint(jobOffer.publicationDate)}</Td>
                                <Td>{jobOffer.expirationDate ? formatDateForPrint(jobOffer.expirationDate) : "Non renseigné"}</Td>
                                <Td>
                                    <IconButton
                                        aria-label='Read'
                                        icon={<FaEye />}
                                        mr={1}
                                        onClick={() => navigate(`/paul-emploi/${jobOffer._id}`)}
                                    />
                                    <IconButton
                                        aria-label='Update'
                                        colorScheme='teal'
                                        icon={<FaPenToSquare />}
                                        onClick={() => navigate(`/paul-emploi/update/${jobOffer._id}`)}
                                        mr={1}
                                    />
                                    <IconButton
                                        onClick={() => {
                                            setToDeletion(jobOffer._id)
                                            onOpen()
                                        }}
                                        colorScheme='red'
                                        aria-label='Delete'
                                        icon={<FaTrash />}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de l'offre d'emploi
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer l'offre d'emploi <b>{toDeletion}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
}

export default JobOffersTable