import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Text, VStack, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API_URL } from "../keys";
import backgroundImage from "../Resources/Images/register_illustration.jpg";
import HorizontalLogo from "../Resources/Logos/HoritzontalLogoTransparency.png";

const CandidateRegisterForm = () => {
    const { team_id } = useParams()
    const [email, setEmail] = useState('')
    const [passwordInfos, setPasswordInfos] = useState({ password: '', confirmPassword: '' })
    const [errors, setErrors] = useState({})
    const [infos, setInfos] = useState({ firstname: '', lastname: '' })
    const toast = useToast()
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        const { value } = e.target;
        if (!checkIfEmailIsValid(value)) {
            setErrors({ ...errors, email: "Le format de l'adresse mail est incorrect." })
        } else {
            if (errors.hasOwnProperty("email")) {
                delete errors.email
                setErrors(errors)
            }
        }
        setEmail(value)
    }

    const handlePasswordChange = (field, e) => {
        const { value } = e.target;
        passwordInfos[field] = value

        if (passwordInfos.password != '' && passwordInfos.confirmPassword != '') {

            if (passwordInfos.password !== passwordInfos.confirmPassword) {
                setErrors({ password: "Les mots de passe ne sont pas identiques." })
            } else {
                if (errors.hasOwnProperty('password')) {
                    delete errors.password;
                }
            }
        } else {
            if (errors.hasOwnProperty('password')) {
                delete errors.password;
            }
        }
        setPasswordInfos({ ...passwordInfos, [field]: value })

    }

    const handleInfosChange = (field, value) => {
        if (value.length < 2) setErrors({ [field]: "Le champ doit contenir au moins 2 charactères." })
        else {
            if (errors.hasOwnProperty(field)) {
                delete errors[field];
            }
        }
        setInfos({ ...infos, [field]: value })
    }

    const checkIfEmailIsValid = (value) => {
        if (value === "")
            return true
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    }

    const handleSubmit = async () => {
        const { firstname, lastname } = infos;
        try {
            const response = await fetch(API_URL + '/users/register_candidate', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ firstname, lastname, email, password: passwordInfos.password, password_confirmation: passwordInfos.confirmPassword, team_id })
            });

            await response.json();

            if (response.ok) {
                navigate("/");
            } else {
                toast({
                    title: 'Erreur.',
                    description: "Une erreur est survenue lors de votre requête.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <Flex>
            <Flex flex={5} direction={"column"}>
                <Box p={4}>
                    <img width={300} src={HorizontalLogo} alt="Aptitude logo horizontal" />
                </Box>
                <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
                    <Box width={500}>
                        <VStack
                            spacing={4}
                            align='stretch'
                        >
                            <Flex gap={4}>
                                <FormControl isInvalid={errors && errors.lastname}>
                                    <FormLabel>Nom</FormLabel>
                                    <Input type='text' value={infos.lastname} onChange={(e) => handleInfosChange('lastname', e.target.value)} placeholder="Renseignez votre nom" />
                                    {errors && errors.lastname && <FormErrorMessage>{errors.lastname}</FormErrorMessage>}
                                </FormControl >
                                <FormControl isInvalid={errors && errors.firstname}>
                                    <FormLabel>Prénom</FormLabel>
                                    <Input type='text' value={infos.firstname} onChange={(e) => handleInfosChange('firstname', e.target.value)} placeholder="Renseignez votre prénom" />
                                    {errors && errors.firstname && <FormErrorMessage>{errors.firstname}</FormErrorMessage>}
                                </FormControl >
                            </Flex>
                            <FormControl>
                                <FormLabel>Équipe</FormLabel>
                                <Input type='text' value={team_id} disabled={true} />
                            </FormControl >
                            <FormControl isInvalid={errors && errors.email}>
                                <FormLabel>Adresse email</FormLabel>
                                <Input type='email' value={email} onChange={handleEmailChange} placeholder="Renseignez votre adresse email" />
                                {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                            </FormControl >
                            <FormControl isInvalid={errors && errors.password}>
                                <FormLabel>Mot de passe</FormLabel>
                                <Input type='password' value={passwordInfos.password} onChange={(e) => handlePasswordChange('password', e)} placeholder="Renseignez votre mot de passe" />
                                {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                            </FormControl >

                            <FormControl isInvalid={errors && errors.password}>
                                <FormLabel>Confirmation du mot de passe</FormLabel>
                                <Input type='password' value={passwordInfos.confirmPassword} onChange={(e) => handlePasswordChange('confirmPassword', e)} placeholder="Renseignez votre mot de passe" />
                                {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                            </FormControl >
                            <Button
                                mt={4}
                                colorScheme='teal'
                                onClick={handleSubmit}
                                mb={0}
                            >
                                Rejoindre l'équipe
                            </Button>
                        </VStack>
                        <Text fontSize='xs' mt={2}>Pas encore de compte recruteur ?</Text>
                    </Box>
                </Flex>
            </Flex>
            <Box flex={2} height={"100vh"} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "bottom" }}>

            </Box>
        </Flex>
    )
}

export default CandidateRegisterForm