import { Box, Checkbox, Code, Flex, Tag, Text } from '@chakra-ui/react'
import React from 'react'
import { GetLabelFromType } from "../Utils/QuestionnairesUtils";
import DynamicComponent from './DynamicComponent';
import ImageFromS3 from '../Utils/ImageFromS3';

const ExamQuestionCard = ({ description, type, image = undefined, ...props }) => {

    return (
        <Box w='100%' boxShadow={"base"} p={4} color="gray.700">
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='2xl'>{description}</Text>
                {/* <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag> */}
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType(type)}</Tag>
            </Flex>
            {image && <ImageFromS3 imageKey={image} />}
            <DynamicComponent type={type} {...props}></DynamicComponent>

        </Box>
    )
}

export default ExamQuestionCard