import { Box, Button, Card, Container, Flex, Tag, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from "../keys";
import { getJobOfferById } from '../API/JobOffers';
import { FaArrowLeft } from 'react-icons/fa6';
import JobOfferHeader from '../Components/JobOfferHeader';

const JobSearchInfosComponent = () => {
  const navigate = useNavigate();
  const { job_id } = useParams();
  const [job, setJob] = useState(null)

  useEffect(() => {
    getJobOfferById(job_id).then((data) => setJob(data))
  }, [])

  return (
    <>
      <Container maxW="full" p={4}>
        <Flex alignItems='center' gap={4} mb={4}>
          <Button onClick={() => navigate("/job_search")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux offres d'emploi</Button>
        </Flex>
        <Flex direction="column" gap={4} mb={6}>
          <JobOfferHeader {...job} />
          <Card p={12}>
              <span dangerouslySetInnerHTML={{ __html: job && job.content }}></span>
          </Card>
        </Flex>
      </Container>
    </>
  )
}

export default JobSearchInfosComponent